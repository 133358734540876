import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../../utils/axios';
import CookieConsent from "../../components/CookieConsent/CookieConsent";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";

const AuthContext = createContext();

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [showLogOutModal, setShowLogOutModal] = useState(false);

  const [profile, setProfile] = useState(null);
  const [profileError, setProfileError] = useState(null);

  const [isAuthLoading, setIsAuthLoading] = useState(true);

  useEffect(() => {
    let userData;
    try {
      userData = JSON.parse(localStorage.getItem('user'));
    } catch (err) {
      userData = null;
    }

    if (userData) {
      setUser(userData);
    } else {
      setIsAuthLoading(false);
    }
  }, []);

    useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
      fetchProfile();
    } else {
      localStorage.removeItem('user');
      setProfile(null);
    }
  }, [user]);



  const fetchProfile = async () => {
    setIsAuthLoading(true);
    try {
      const response = await axios.get('/profile');
      setProfile(response.data);
    } catch (err) {
      setProfileError(err);
    } finally {
      setIsAuthLoading(false);
    }
  };

  const logOut = () => {
    setShowLogOutModal(true);
  };

  const clearSession = async () => {
    try {
      await axios.get('/auth/logout');
      setShowLogOutModal(false);
      setUser(null);
      clearToken();
    } catch (err) {
      console.error(err);
    }
  };

  const LogOutModalComponent = () => {
    if (!showLogOutModal) return null;

    const closeLogOutModal = () => {
      setShowLogOutModal(false);
    };

    return (
      <Modal show={true} allowCloseVeilClick={true} handleClose={closeLogOutModal} size="small">
        <h3 className="text-2xl leading-6 font-bold text-gray-900 text-left">
          Log out
        </h3>
        <div className="text-left my-8 text-base text-gray-500">
          <p>Are you sure you want to log out?</p>
        </div>
        <div className="flex flex-row-reverse gap-x-4">
          <Button onClick={clearSession} variant="primary" size="small">
            Log Out
          </Button>
          <Button onClick={closeLogOutModal} variant="tertiary" size="small">
            Cancel
          </Button>
        </div>
      </Modal>
    );
  };

  const setToken = (token) => {
    localStorage.setItem('accesstoken', token);
  };

  const clearToken = () => {
    localStorage.removeItem('accesstoken');
  };

  const hasRole = (roles) => {
    return roles.includes(profile.roles.primary);
  };

  const refreshProfile = async () => {
    setIsAuthLoading(true);
    try {
      const response = await axios.get('/profile');
      setProfile(response.data);
    } catch (err) {
      setProfileError(err);
    } finally {
      setIsAuthLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isAuthLoading,
        setToken,
        logOut,
        LogOutModalComponent,
        profile,
        profileError,
        refreshProfile,
        hasRole,
      }}
    >
      {children}
      <CookieConsent />
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
